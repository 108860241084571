<template>
  <footer class="style-1">
    <b-container>
      <b-row>
        <b-col lg="5" cols="12">
          <span class="copyright">
            {{ $t("footer.copyRight") }} &copy;
            <a href="#">Stellar Software Development</a> 2019
          </span>
        </b-col>
        <b-col lg="5" cols="12">
          <!--<div class="footer-social text-center">
            <ul>
              <li>
                <a href="#">
                  <i>
                    <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }"/>
                  </i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i>
                    <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }"/>
                  </i>
                </a>
              </li>

              <li>
                <a href="#">
                  <i>
                    <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'linkedin' }"/>
                  </i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i>
                    <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'google-plus' }"/>
                  </i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i>
                    <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'dribbble' }"/>
                  </i>
                </a>
              </li>
            </ul>
          </div>-->
        </b-col>
        <b-col lg="4" cols="12" class="text-right">
          <!--<div class="footer-link">-->
            <!--<ul class="pull-right">-->
              <!--<li>-->
                <!--<a href="#">{{ $t("footer.PrivacyPolicy") }}</a>-->
              <!--</li>-->
              <!--<li>-->
                <!--<a href="#">{{ $t("footer.TermsOfUse") }}</a>-->
              <!--</li>-->
            <!--</ul>-->
          <!--</div>-->
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>
<script>
export default {
  name: "Footer"
};
</script>
