<template>
  <section id="feature" class="feature-section">
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="section-title text-center">
            <h3>{{ $t("feature.title") }}</h3>
            <p></p> <!-- this serves only for spacing TODO remove and solve spacing properly -->
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="3" sm="6" cols="12" class="no-touch"  v-bind:key="index" v-for="(feature, index) in features">
          <div class="feature">
            <i>
              <font-awesome-icon :icon="feature.icon"/>
            </i>
            <div class="feature-content">
              <h4>{{feature.name}}</h4>
              <p>{{feature.description}}</p>
            </div>
          </div>
        </b-col>
        <!-- /.col-lg-3 -->
      </b-row>
      <!-- /.row -->
    </b-container>
    <!-- /.container -->
  </section>
  <!-- End Feature Section -->
</template>
<script>
import Features from '../services/features.service.js';

export default {
  name: "Features",
  data () {
    return {
      features: []
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.features = Features.getData(this.$i18n.locale)
    }
  }
};
</script>
