const AboutUs = {
  getData: lang => {
    return aboutUs[lang]["data"];
  },
  getImages: () => {
    return [
      {
        img: require("../assets/images/about-01-min.jpg")
      },
      {
        img: require("../assets/images/about-02-min.jpg")
      },
      {
        img: require("../assets/images/about-03-min.jpg")
      }
    ];
  },
  getSkills: lang => {
    return aboutUs[lang]["skills"];
  }
};

const aboutUs = {
  en: {
    data: [
      {
        name: "Our Target",
        img: require("../assets/images/about-02-min.jpg"),
        description: "We aim to take away your concerns related to software and make it a sound foundation upon which" +
          " you can expand your business."
          // "Help save your business from disaster of not working with us"
      },
      {
        name: "Communication",
        img: require("../assets/images/about-01-min.jpg"),
        description:
          "Cooperating on challenging projects requires not only high individual skill, but also efficient and reliable " +
          "communication. We are in it together!"
      },
      {
        name: "Open Source",
        img: require("../assets/images/about-03-min.jpg"),
        description:
          "We are powered and inspired by countless open-source communities and projects."
      }
    ],
    skills: [
      {
        name: "Language & communication barrier",
        percentage: 10
      },
      {
        name: "Paperwork",
        percentage: 20
      },
      // {
      //   name: "Effort required from client",
      //   percentage: 30
      // },
      {
        name: "Price - compared to competitors",
        percentage: 60
      },
      {
        name: "Quality - striving for the highest",
        percentage: 90
      },
      {
        name: "Customer satisfaction",
        percentage: 100
      }
    ]
  },
  de: {
    data: [
      {
        name: "Office Philosophy 1111",
        img: require("../assets/images/about-01-min.jpg"),
        description:
          "Duis aute irure dolor in reprehen derit in voluptate velit essecillum dolore eu fugiat nulla pariatur. Lorem reprehenderit"
      },
      {
        name: "Office Mission & Vission 111",
        img: require("../assets/images/about-02-min.jpg"),
        description:
          "Duis aute irure dolor in reprehen derit in voluptate velit essecillum dolore eu fugiat nulla pariatur. Lorem reprehenderit"
      },
      {
        name: "Office Value & Rules",
        img: require("../assets/images/about-03-min.jpg"),
        description:
          "Duis aute irure dolor in reprehen derit in voluptate velit essecillum dolore eu fugiat nulla pariatur. Lorem reprehenderit"
      }
    ],
    skills: [
      {
        name: "Web design",
        percentage: 70
      },
      {
        name: "HTML & CSS",
        percentage: 95
      },
      {
        name: "Wordpress",
        percentage: 86
      },
      {
        name: "Joomla",
        percentage: 100
      },
      {
        name: "Extension",
        percentage: 85
      }
    ]
  }
};
export default AboutUs;
