import Vue from 'vue'
import router from './router'
import App from './App.vue'

Vue.config.productionTip = false

const isProd = process.env.NODE_ENV === 'production'

// bootstrap
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import VueAnalytics from 'vue-analytics'

// custom stylings
import './assets/css/style.css'
import './assets/css/color/green.css'
import './assets/css/animate.css'
import './assets/css/responsive.css'

Vue.use(BootstrapVue)

import VeeValidate from 'vee-validate';

Vue.use(VeeValidate);
// font awesome
import {library} from '@fortawesome/fontawesome-svg-core'
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons'
import {faAngleRight} from '@fortawesome/free-solid-svg-icons'
import {faLaptopCode} from '@fortawesome/free-solid-svg-icons'
import {faPlug} from '@fortawesome/free-solid-svg-icons'
import {faMobileAlt} from '@fortawesome/free-solid-svg-icons'
import {faDatabase} from '@fortawesome/free-solid-svg-icons'
import {faLink} from '@fortawesome/free-solid-svg-icons'
import {faCube} from '@fortawesome/free-solid-svg-icons'
import {faCode} from '@fortawesome/free-solid-svg-icons'
import {faMale} from '@fortawesome/free-solid-svg-icons'
import {faFileUpload} from '@fortawesome/free-solid-svg-icons'
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import {faBars} from '@fortawesome/free-solid-svg-icons'
import {faFacebook} from '@fortawesome/free-brands-svg-icons'
import {faLinkedin} from '@fortawesome/free-brands-svg-icons'
import {faTwitter} from '@fortawesome/free-brands-svg-icons'
import {faPinterest} from '@fortawesome/free-brands-svg-icons'
import {faDribbble} from '@fortawesome/free-brands-svg-icons'
import {faGooglePlus} from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faTasks} from '@fortawesome/free-solid-svg-icons'
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons'
import {faMagic} from '@fortawesome/free-solid-svg-icons'
import {faBusinessTime} from '@fortawesome/free-solid-svg-icons'
import {faAngleUp} from '@fortawesome/free-solid-svg-icons'
import {faLayerGroup} from '@fortawesome/free-solid-svg-icons'
import {faNetworkWired} from '@fortawesome/free-solid-svg-icons'

library.add(faAngleLeft)
library.add(faNetworkWired)
library.add(faLayerGroup)
library.add(faAngleRight)
library.add(faAngleUp)
library.add(faLaptopCode)
library.add(faPlug)
library.add(faMobileAlt)
library.add(faDatabase)
library.add(faLink)
library.add(faCube)
library.add(faMale)
library.add(faCode)
library.add(faFileUpload)
library.add(faCheck)
library.add(faFacebook)
library.add(faLinkedin)
library.add(faTwitter)
library.add(faPinterest)
library.add(faDribbble)
library.add(faGooglePlus)
library.add(faBars)
library.add(faTasks)
library.add(faCheckCircle)
library.add(faMagic)
library.add(faBusinessTime)

Vue.component('font-awesome-icon', FontAwesomeIcon)

// scroll to
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);

// localization
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import messages from './lang/messages';

// Create VueI18n instance with options
let uri = window.location.search.substring(1);
let params = new URLSearchParams(uri);
let lang = params.get("lang");
if (lang !== 'en' && lang !== 'de' && lang !== 'rs') {
  lang = 'en';
}

const i18n = new VueI18n({
  locale: lang, // set locale
  fallbackLocale: 'en',
  messages, // set locale messages
})

Vue.use(VueAnalytics, {
  id: 'UA-136076788-1',
  router,
  debug: {
    enabled: !isProd,
    sendHitTask: isProd
  }
})

new Vue({
  router,
  render: h => h(App),
  i18n
}).$mount('#app')
