<template>
  <div id="app">
    <div class="loader" :v-if="loading">
      <clip-loader :loading="loading" color="#43A047"></clip-loader>
    </div>
    <router-view :v-if="!loading"></router-view>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  name: "app",
  data(){
    return {
      loading: true,
    }
  },
  created() {
    this.loading = true;
  },
  mounted() {
    document.querySelector('.loaderImg').style.display = 'none';
    this.$nextTick(function () {
      this.loading = false;
    })
  },
  components: {
    ClipLoader,
  }
};
</script>

<style>
  @import url('https://fonts.googleapis.com/css?family=Montserrat');
  .loader {
    position: fixed;
    top: 50%;
    width: 100%;
    z-index: 1000;
  }
</style>

