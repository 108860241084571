const Features = {
  getData: (lang) => {
    return data[lang];
  }
}

const data = {
  en: [
    {
      name: 'Web Development',
      icon: 'laptop-code',
      description: 'Increase your company\'s productivity with a custom CMS, CRM or a data dashboard. ' +
        'We keep your software up to date with latest industry standards.'
    },
    {
      name: 'Mobile Apps',
      icon: 'mobile-alt',
      description: 'Enrich your business with a bespoke app that helps you maintain and better understand your customer base. Customers always' +
        ' come first!'
    },
    {
      name: 'Database Design',
      icon: 'database',
      description: 'SQL or no-SQL - we design the data concept uniquely for your needs and take care of your database. Your data is in safe hands.'
    },
    {
      name: 'API Integration',
      icon: 'network-wired',
      description: 'APIs are the essence of modern business. We develop your APIs and integrate 3rd-party systems and interfaces of your choice.'
    },

  ],
  de: [
    {
      name: 'Web Design',
      icon: 'magic',
      description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor. reprehenderit'
    },
    {
      name: 'Graphics Design',
      icon: 'gift',
      description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor. reprehenderit'
    },
    {
      name: 'Wordpress Theme',
      icon: 'compact-disc',
      description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor. reprehenderit'
    },
    {
      name: 'Wordpress Plugin',
      icon: 'plug',
      description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor. reprehenderit'
    },

  ]


}
export default Features;
