<template>
  <section id="contact" class="contact">
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="section-title text-center">
            <h3>{{ $t("contact.title") }}</h3>
            <p class="white-text">{{ $t("contact.description") }}</p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <form
            name="contact"
            id="contactForm"
            action="/thanks"
            novalidate
            method="POST"
            netlify-honeypot="bot-field"
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value="contact" />
            <p class="hidden">
              <label>
                Don’t fill this out if you're human:
                <input name="bot-field" />
              </label>
            </p>
            <b-row>
              <b-col lg="6">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('contact.yourName')"
                    id="name"
                    v-validate="formValidation.name"
                    required
                    name="name"
                    @input="ev => form.name = ev.target.value"
                  />
                  <p
                    class="help-block text-danger"
                    v-if="errors.first('name')"
                  >{{ $t('contact.enter_your_name') }}</p>
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    :placeholder="$t('contact.yourEmail')"
                    id="email"
                    v-validate="formValidation.email"
                    required
                    name="email"
                    @input="ev => form.email = ev.target.value"
                  />
                  <p
                    class="help-block text-danger"
                    v-if="errors.first('email')"
                  >{{ $t('contact.enter_your_email_address') }}</p>
                </div>
                <div class="form-group">
                  <input
                    type="tel"
                    class="form-control"
                    :placeholder="$t('contact.yourPhone')"
                    id="phone"
                    v-validate="formValidation.phone"
                    name="phone"
                    @input="ev => form.phone = ev.target.value"
                  />
                  <p
                    class="help-block text-danger"
                    v-if="errors.first('phone')"
                  >{{ $t('contact.enter_your_phone') }}</p>
                </div>
              </b-col>
              <b-col lg="6">
                <div class="form-group">
                  <textarea
                    class="form-control"
                    :placeholder="$t('contact.yourMessage')"
                    id="message"
                    required
                    v-validate="formValidation.message"
                    name="message"
                    @input="ev => form.message = ev.target.value"
                  ></textarea>
                  <p
                    class="help-block text-danger"
                    v-if="errors.first('message')"
                  >{{ $t('contact.enter_your_message') }}</p>
                </div>
              </b-col>
              <div class="clearfix"></div>
              <b-col cols="12" class="text-center">
                <div id="success"></div>
                <button type="submit" class="btn btn-primary">{{ $t('contact.send_message') }}</button>
              </b-col>
            </b-row>
          </form>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" lg="4">
          <div class="footer-contact-info">
            <h4>{{ $t('contact.contactInfo') }}</h4>
            <ul>
              <li>
                <strong>{{ $t('contact.email') }}</strong>
                {{contactData.email}}
              </li>
              <li>
                <strong>{{ $t('contact.phone') }}</strong>
                {{contactData.phone}}
              </li>
              <!-- <li>
                <strong>{{ $t('contact.mobile') }}</strong> {{contactData.mobile}}
              </li>-->
              <li>
                <strong>{{ $t('contact.web') }}</strong>
                {{contactData.web}}
              </li>
            </ul>
          </div>
        </b-col>
        <!--        <b-col cols="12" lg="4" offset-lg="4">
          <div class="footer-contact-info">
            <h4>{{ $t('contact.WorkingHours') }}</h4>
            <ul>
              <li  v-bind:key="index" v-for="(workingHours, index) in contactData.workingHours">
                <strong>{{workingHours.days}}: </strong> {{workingHours.time}}
              </li>
            </ul>
          </div>
        </b-col>-->
      </b-row>
    </b-container>
  </section>
</template>
<script>
import Contact from "../services/contact.service.js";

export default {
  name: "ContactUs",
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
        message: ""
      },
      formValidation: {
        name: {
          alpha_spaces: true,
          required: true,
          min: 3
        },
        email: {
          email: true,
          required: true
        },
        phone: {
          required: true
        },
        message: {
          required: true
        }
      },
      contactData: {}
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData: function() {
      this.contactData = Contact.getData(this.$i18n.locale);
    },
    validateForm() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          // console.log("valid");
        }
      });
    }
  }
};
</script>
<style>
#contact {
  padding-bottom: 100px;
}
.hidden {
  display: none;
}
</style>
