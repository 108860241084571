const Slider = {
  getImages: (lang) => {
    return data[lang];
  }
}

const data = {
  en: [
    // {
    //   img: require('../assets/images/header-bg-1.jpg'),
    //   name: '<strong>Fame</strong> for the highest',
    //   link: '#feature',
    //   description: 'At vero eos et accusamus et iusto odio dignissimos<br>ducimus qui blanditiis praesentium voluptatum'
    // },
    {
      img: require('../assets/images/abstract-astrology-min.jpg'),
      name: '<strong>Stellar</strong> Software',
      link: '#contact',
      description: 'Developing software solutions of high quality, tailored to your needs.'
    },
    // {
    //   img: require('../assets/images/galaxy.jpg'),
    //   name: 'The way of <strong>Success</strong>',
    //   link: '#feature',
    //   description: 'At vero eos et accusamus et iusto odio dignissimos<br>ducimus qui blanditiis praesentium voluptatum'
    // },
  ],
  de: [
    // {
    //   img: require('../assets/images/header-bg-1.jpg'),
    //   name: '<strong>Fame</strong> for the highest',
    //   link: '#feature',
    //   description: 'At vero eos et accusamus et iusto odio dignissimos<br>ducimus qui blanditiis praesentium voluptatum'
    // },
    {
      img: require('../assets/images/abstract-astrology-min.jpg'),
      name: 'Welcome to<strong>Fame</strong>',
      link: '#contact',
      description: 'At vero eos et accusamus et iusto odio dignissimos<br>ducimus qui blanditiis praesentium voluptatum'
    },
    // {
    //   img: require('../assets/images/galaxy.jpg'),
    //   name: 'The way of <strong>Success</strong>',
    //   link: '#feature',
    //   description: 'At vero eos et accusamus et iusto odio dignissimos<br>ducimus qui blanditiis praesentium voluptatum'
    // },
  ]
}
export default Slider;
