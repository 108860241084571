<template>
  <!-- Start About Us Section -->
  <section id="about-us" class="about-us-section-1">
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="section-title text-center">
            <h3>{{ $t("aboutUs.title") }}</h3>
            <p>{{ $t("aboutUs.description") }}</p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4"  v-bind:key="index" v-for="(aboutUs, index) in data">
          <div class="welcome-section text-center">
            <img :src="aboutUs.img" class="img-responsive" alt="..">
            <h4>{{aboutUs.name}}</h4>
            <div class="border"></div>
            <p>{{aboutUs.description}}</p>
          </div>
        </b-col>

      </b-row>
      <!-- /.row -->
    </b-container>
    <!-- /.container -->
    <!-- Start About Us Section 2 -->
    <div class="about-us-section-2">
      <b-container>
        <b-row>
          <b-col lg="6">
            <div class="skill-shortcode">
              <!-- Progress Bar -->
              <progress-bar v-bind:key="index" :skill="skill" v-for="(skill, index) in skills"/>
            </div>
          </b-col>

          <b-col lg="6" class="about-us-carousel">
            <carousel :autoplay="true" :responsive="{0:{items:1,nav: false}}">
              <div class="item active" v-bind:key="index" v-for="(image, index) in images">
                <img :src="image.img" alt>
              </div>
            </carousel>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- Start About Us Section 2 -->
  </section>
  <!-- End About Us Section -->
</template>
<script>
import carousel from 'v-owl-carousel';
import ProgressBar from './ProgressBar';
import AboutUs from '../services/about_us.service.js';

export default {
  name: "AboutUs",
  components: {
    carousel,
    ProgressBar
  },
  data() {
    return {
      skills: [],
      data: [],
      images: []
    };
  },
  created() {
    this.getSkills();
    this.getAboutUsData();
    this.getSliderImages();
  },
  methods: {
    getSkills () {
      this.skills =  AboutUs.getSkills(this.$i18n.locale);
    },
    getAboutUsData () {
      this.data = AboutUs.getData(this.$i18n.locale);
    },
    getSliderImages () {
       this.images = AboutUs.getImages();
    }
  }
};
</script>
<style>
.about-us-carousel .owl-theme .owl-dot span {
  border: 2px solid #43A047 !important;
  background: transparent;
  width: 14px !important;
  height: 14px !important;
}

.about-us-carousel .owl-theme .owl-dot.active span {
  background: #43A047;
}
</style>

