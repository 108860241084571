import Vue from "vue";
import Router from "vue-router";
import Home from "../pages/Home";
import SubmissionSuccess from "../pages/SubmissionSuccess"
import SubmissionFail from "../pages/SubmissionFail"

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/thanks",
      name: "success",
      component: SubmissionSuccess
    },
    {
      path: "/404",
      name: "fail",
      component: SubmissionFail
    }
  ],
  mode: 'history'
})

export default router;
