const CallToAction = {
  getData: language => {
    return CallToActionData[language];
  }
};

const CallToActionData = {
  en: {
    name:
    "Are you a start-up looking to build your first MVP? <br /> Or an established company seeking to enhance your" +
      " software portfolio?",
    button: "We can help !"
  },
  de: {
    name:
    "Libero tempore soluta nobis est eligendi</br> optio cumque nihil impedit minus id quod maxime </br>placeat facere possimus, omnis voluptas assumenda est",
    button: "Buy This Template"
  }
};

export default CallToAction;
