const Services = {
  getData: (lang) => {
    return services[lang];
  }
}

const services = {
  en: [
    {
      name: 'Web Applications',
      icon: 'laptop-code',
      description: 'Custom CMS, CRM, data dashboards and Progressive Web Apps.'
    },
    {
      name: 'Project Management',
      icon: 'tasks',
      description: 'We manage and control project timeline and costs.'
    },
    {
      name: 'Database Design',
      icon: 'database',
      description: 'We design, develop and maintain your database while keeping your data safe.'
    },
    {
      name: 'Apps',
      icon: 'mobile-alt',
      description: 'Native and hybrid apps for mobile devices of different sizes.'
    },
    {
      name: 'API',
      icon: 'network-wired',
      description: 'Design, development and automated testing of your mission-critical APIs.'
    },
    {
      name: 'Integrations & interfaces',
      icon: 'layer-group',
      description: 'We empower your business by integrating 3rd-party interfaces.'
    },
    {
      name: 'Quality Assurance',
      icon: 'check-circle',
      description: 'Testing plays an essential role in our software development cycle.'
    },
    {
      name: 'Website Design',
      icon: 'magic',
      description: 'Enhance your outside appearance with a custom-tailored website.'
    },
    {
      name: 'Consulting',
      icon: 'business-time',
      description: 'Need advice? Or a (harsh) friendly opinion? We\'re here to help!'
    }
  ],
  de: [
    {
      name: 'Joomla Template',
      icon: 'cube',
      description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu'
    },
    {
      name: 'Wordpress Plugin',
      icon: 'magic',
      description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu'
    },
    {
      name: 'Web Design',
      icon: 'magic',
      description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu'
    },
    {
      name: 'Joomla Template',
      icon: 'plug',
      description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu'
    }
  ]
};

export default Services;
