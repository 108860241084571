<template>
  <section id="page-top">
    <div id="main-slide" class="carousel">
      <carousel :autoplay="true" :dots="true" :responsive="{0:{items:1,nav: true}}">
        <div class="item active"  v-bind:key="index" v-for="(image, index) in slider">
          <img  v-cloak class="img-responsive" :src="image.img" alt="slider">
          <div class="slider-content">
            <div class="col-md-12 text-center" v-b-scrollspy="{element: '#nav-scroller', offset: 100}">
              <h1 class="animated3">
                <span v-html="image.name"></span>
              </h1>
              <p class="animated2" v-html="image.description"></p>
              <a :href="image.link"  @click="scrollIntoView" class="page-scroll btn btn-primary animated1">{{ $t("slider.readMore") }}</a>
            </div>
          </div>
        </div>
      </carousel>
    </div>
  </section>
</template>
<script>
import carousel from "v-owl-carousel";
import Slider from '../services/slider.service.js'
export default {
  name: "Slider",
  data(){
    return {
      slider: []
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.slider = Slider.getImages(this.$i18n.locale);
    },
    scrollIntoView(evt) {
      evt.preventDefault();
      const href = evt.target.getAttribute("href");
      const el = href ? document.querySelector(href) : null;
      this.$scrollTo(el, 500, {});
    },
  },
  components: {
    carousel
  }
};
</script>
<style>
@media (max-width: 768px){
  #main-slide .item .slider-content {
    display: block !important;
    margin-top: -100px !important;
  }
  #main-slide .item {
    height: calc(100vh - 98px) !important;
  }
  #main-slide .item img{ 
    height: 100%;
    width: auto;
  }
}
</style>