<template>
    <!-- Start Call to Action Section -->
    <section class="call-to-action">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h1 v-html="data.name"></h1>
                    <button href="#contact" @click="scrollIntoView" class="btn btn-primary">{{data.button}}</button>
                </div>
            </div>
        </div>
    </section>
    <!-- End Call to Action Section -->
</template>

<script>
    import CallToAction from '../services/call_to_action.service.js';
  export default {
    name: 'CallToAction',
    data() {
        return {
            data: {}
        }
    },
    created () {
        this.getData();
    },
    methods: {
        getData () {
            this.data = CallToAction.getData(this.$i18n.locale);
        },
        scrollIntoView(evt) {
            evt.preventDefault();
            const href = evt.target.getAttribute("href");
            const el = href ? document.querySelector(href) : null;
            this.$scrollTo(el, 500, {});
        }
    }
  }
</script>
<style>
@media (max-width : 600px) {
    .call-to-action {
        min-height: 100vh;
    }
}

</style>
