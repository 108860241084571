<template>
    <div>
        <!--<LanguageSwitcher />-->
        <NavBar/>
        <slider/>
        <features/>
        <call-to-action/>
        <!-- <portfolio/> -->
        <about-us/>
        <services/>
        <!-- <fun-facts/>       -->
        <!-- <our-team/>       -->
        <!-- <pricing-plan/>   -->
        <!-- <testimonial/>    -->
        <!-- <clients/>        -->
        <contact-us/>
        <Footer/>
    </div>
</template>

<script>
  // import LanguageSwitcher from './components/LanguageSwitcher';
  import NavBar from "../components/NavBar.vue";
  import Slider from "../components/Slider";
  import Features from "../components/Features";
  import CallToAction from "../components/CallToAction";
  // import Portfolio from "./components/Portfolio";
  import AboutUs from "../components/AboutUs";
  import Services from "../components/Services";
  // import FunFacts from "./components/FunFacts";
  // import OurTeam from "./components/OurTeam";
  // import PricingPlan from "./components/PricingPlan";
  // import Testimonial from "./components/Testimonial";
  // import Clients from "./components/Clients";
  import ContactUs from "../components/ContactUs";
  import Footer from "../components/Footer";
  export default {
    name: "Home",
    components: {
      // LanguageSwitcher,
      NavBar,
      Slider,
      Features,
      CallToAction,
      // Portfolio,
      AboutUs,
      Services,
      // FunFacts,
      // OurTeam,
      // PricingPlan,
      // Testimonial,
      // Clients,
      ContactUs,
      Footer
    }
  }
</script>

<style scoped>

</style>
