<template>
    <div>
        <h1>Thanks, you're awesome! Your response has been recorded!</h1>
        <router-link to="/">
            <button>Back to form</button>
        </router-link>
    </div>
</template>

<script>
  export default {
    name: "SubmissionSuccess"
  }
</script>

<style scoped>

</style>
