<template>
  <div href="#navbar" v-b-scrollspy="{element: '#nav-scroller', offset: -100}" >
    <a href="#navbar" @click="goToTopOfThePage($event)" v-bind:class="{ 'scrolled' : scrolled }" class="btn btn-primary bmd-btn-fab go-to-top">
      <font-awesome-icon  href="#navbar" icon="angle-up" size="3x" />
    </a>
  </div>
</template>
<script>
export default {
  name: 'GoToPage',
  props: ['scrolled'],
  methods: {
    goToTopOfThePage() {
      this.$scrollTo(document.querySelector('#navbar'), 500, {});    },
  },
}
</script>
<style>
.go-to-top {
  padding: 10px 20px  !important;
  border-radius: 40px !important;
  position: fixed;
  z-index: 1000;
  right: 20px;
  bottom: 20px;
  background: #26C122  !important;
  border-color: #26C122  !important;
  display: none !important;
}
.go-to-top.scrolled {
  display: block !important;
}
.go-to-top:hover {
  -webkit-box-shadow: 10px 11px 21px -13px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 11px 21px -13px rgba(0,0,0,0.75);
  box-shadow: 10px 11px 21px -13px rgba(0,0,0,0.75);
  border-color: #26C122 !important;
}
</style>
