const messages = {
  en: {
    navBar: {
      hello: 'hello world',
      Feature: 'Solutions',
      Portfolio: 'Portfolio',
      AboutUs: 'About us',
      Service: 'Services',
      Team: 'Team',
      Pricing: 'Pricing',
      LatestNews: 'Latest News',
      Testimonials: 'Testimonials',
      Partner: 'Partner',
      Contact: 'Contact'

    },
    feature: {
      title: 'Solutions'
    },
    aboutUs: {
      title: 'About Us',
      description: 'Situated in Belgrade, Serbia, Stellar Software Development DOO has been founded with the goal of' +
        ' aiding start-ups and traditional companies by providing Software Engineering services.'
    },
    clients: {
      title: 'Our Honorable Partner',
      description: 'Duis aute irure dolor in reprehenderit in voluptate'
    },
    contact: {
      title: 'Contact Us',
      description: 'Drop us a note and we\'ll get back to you stat. Your data won\'t be used for any other purpose, and will be deleted' +
        ' right after we reply to your inquiry.',
      yourName: 'Your Name *',
      yourEmail: 'Your Email *',
      yourPhone: 'Your Phone',
      yourMessage: 'Your Message *',
      enter_your_name: 'Please enter your name.',
      enter_your_phone: 'Please enter your phone number.',
      enter_your_message: 'Please enter a message.',
      send_message: 'Send Message',
      enter_your_email_address: 'Please enter your email address.',
      contactInfo: 'Contact info',
      email: 'E-mail :',
      phone: 'Phone :',
      mobile: 'Mobile :',
      web: 'Web :',
      WorkingHours: 'Contact Hours',
    },
    footer: {
      copyRight: 'Copyright',
      PrivacyPolicy: 'Privacy Policy',
      TermsOfUse : 'Terms of Use'
    },
    latestNews: {
      title: 'Latest News',
      description: 'Duis aute irure dolor in reprehenderit in voluptate',
      ReadMore: 'Read More'
    },
    ourTeam: {
      title: 'Our Team',
      description: 'Duis aute irure dolor in reprehenderit in voluptate',
    },
    portfolio: {
      title: 'Portfolio',
      titleDetails: 'Portfolio Details',
      description: 'Duis aute irure dolor in reprehenderit in voluptate',
      descriptionDetails: 'Duis aute irure dolor in reprehenderit in voluptate'
    },
    pricingPlan: {
      title: 'Pricing Plan',
      description: 'Duis aute irure dolor in reprehenderit in voluptate',
      SignUpNow: 'Sign Up Now'
    },
    services: {
      title: 'Our Services',
      description: 'What we can do for you',
    },
    slider: {
      readMore: 'Get in touch'
    }

  },
  de: {
    message: {
      hello: 'こんにちは、世界'
    }
  }
}

export default messages;
