<template>
<div v-bind:class="{ scrolledNavbar: scrollTop > 30 }">
 <b-navbar
    toggleable="md"
    type="default"
    ref="nav"
    class="navbar-shrink"
    v-bind:class="{ scrolled: scrollTop > 30 }"
    :sticky="true"
    id="navbar"
  >
    <b-container class="nav">
      <b-navbar-brand v-b-scrollspy="{element: '#nav-scroller', offset: 0}" href="#page-top">
        <a class="navbar-brand page-scroll" @click="scrollIntoView" href="#page-top">
          <img src="../assets/logo.png" href="#page-top" title="stellar software development" alt="stellar software development" />
        </a> 
      </b-navbar-brand>
      <b-navbar-toggle target="nav_collapse">
        <font-awesome-icon icon="bars" color="#fff"/>
      </b-navbar-toggle>

      <!-- Collect the nav links, forms, and other content for toggling -->
      <b-collapse is-nav id="nav_collapse">
        <b-nav right v-b-scrollspy="{element: '#nav-scroller', offset: -100 }">
          <b-nav-item href="#feature" @click="scrollIntoView">{{ $t("navBar.Feature") }}</b-nav-item>
          <b-nav-item href="#about-us" @click="scrollIntoView">{{ $t("navBar.AboutUs") }}</b-nav-item>
          <b-nav-item href="#service" @click="scrollIntoView" >{{ $t("navBar.Service") }}</b-nav-item>
          <b-nav-item @click="scrollIntoView" href="#contact">{{ $t("navBar.Contact") }}</b-nav-item>
        </b-nav>
      </b-collapse>
      <!-- /.navbar-collapse -->
    </b-container>
    <!-- /.container-fluid -->
  </b-navbar>
  <go-to-top
    :scrolled="scrollTop > 30"
  ></go-to-top>
</div>
 
</template>
<script>
import GoToTop from './GoToTop';

export default {
  name: "NavBar",
  data() {
    return {
      scrollTop: this.getScrollTop(),
    };
  },
  methods: {
    getScrollTop() {
      return window.pageYOffset !== undefined
        ? window.pageYOffset
        : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop;
    },
    onActivate() {
      this.scrollTop = this.getScrollTop();
    },
    scrollIntoView(evt) {
      evt.preventDefault();
      const href = evt.target.getAttribute("href");
      const el = href ? document.querySelector(href) : null;
      this.$scrollTo(el, 1000, {});
    },
  },
  created() {
    window.addEventListener("scroll", this.onActivate);
  },
  components: {
    GoToTop
  }
};
</script>
<style>
#nav_collapse {
  justify-content: flex-end;
}
.navbar-shrink {
  font-size: 10px;
  background: transparent !important;
}
.navbar-shrink .navbar-collapse {
  margin-top: 10px;
}
.navbar-shrink .navbar-brand {
  font-size: 2rem !important;
}
.navbar-brand img {
  width: 168px;
}
.navbar-shrink.scrolled .navbar-collapse {
  margin-top: 0px;
}
.navbar-shrink.scrolled .navbar-brand {
  font-size: 1.5rem !important;
}
.navbar-default .nav li li a {
  padding: 10px 10px;
}
.navbar-shrink.scrolled {
  /*background: #222 !important;*/
  background: #ffffff !important;
}
.navbar-toggler {
  background: #43A047 !important;
}
.nav-item {
  padding: 10px 0;
}
.nav-item.active {
  background: #43A047;
  border-radius: 3px;
}
/*.nav-item.active a.active:hover,*/
.nav-item.active a
/*.navbar-default .nav li a:hover,*/
/*.navbar-default .nav li a:focus*/ {
  color: #fff !important;
}
@media only screen and (min-width: 768px) {
  #navbar.scrolled {
    position: fixed;
    top: 0;
    width: 100%;
  }
  .scrolledNavbar {
    height: 80px;
  }
} 
@media only screen and (max-width: 767px) {
  .navbar-default .nav li {
    display: block;
    width: 100%;
  }
  .navbar-shrink {
    background: #fff !important;
    position: relative !important;
  }
  #navbar .navbar-collapse {
    position: absolute;
    top: 98px;
    width: 100%;
    background: white;
    margin: 0px;
    padding: 0px 16px;
    left: 0px;
  }
}
</style>
