const Contact = {
  getData (lang) {
    return {
      email: 'office@stellar-software.com',
      phone: '+43 676 9093 779',
      mobile: '+8801-45565378',
      web: 'stellar-software.com',
      workingHours: workingHours[lang]
    }
  }
};

const workingHours = {
  en: [
    {
      days: 'Mon - Fri ',
      time: '11 am to 8 pm'
    },
    // {
    //   days: 'Thurs-Fri',
    //   time: '12:00 - 18:00'
    // },
    {
      days: 'Sat',
      time: '1 pm to 6 pm'
    },
    {
      days: 'Sunday',
      time: 'N/A'
    }
  ],
  de: [
    {
      days: 'Mon-Wed ',
      time: '9 am to 5 pm'
    },
    {
      days: 'Thurs-Fri',
      time: '12 pm to 10 pm'
    },
    {
      days: 'Sat',
      time: '9 am to 3 pm'
    },
    {
      days: 'Sunday',
      time: 'Closed'
    }
  ]
}
export default Contact;
