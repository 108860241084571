<template>
  <div class="skill">
    <p>{{ skill.name }}</p>          
    <div class="progress">         
        <div class="progress-bar" v-bind:style="{ width: width + '%' }" role="progressbar">
            <span class="progress-bar-span" >{{skill.percentage}}%</span>
            <span class="sr-only">{{skill.percentage}}% Complete</span>
        </div>
    </div>  
  </div>                              
</template>
<script>
export default {
  name: 'ProgressBar',
  props: {
    skill: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      width:0
    }
  },
  methods: {
    generateWidth: function() {
      this.width = this.skill.percentage;
 
    }
  },
  mounted: function () {
    this.generateWidth();
  }

}
</script>
<style>
@media screen and (max-width: 600px){
  .progress-bar {
    min-width: 62px;
  }
}
</style>