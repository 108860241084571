import { render, staticRenderFns } from "./SubmissionSuccess.vue?vue&type=template&id=84f8eeb6&scoped=true&"
import script from "./SubmissionSuccess.vue?vue&type=script&lang=js&"
export * from "./SubmissionSuccess.vue?vue&type=script&lang=js&"
import style0 from "./SubmissionSuccess.vue?vue&type=style&index=0&id=84f8eeb6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84f8eeb6",
  null
  
)

component.options.__file = "SubmissionSuccess.vue"
export default component.exports