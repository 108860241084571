<template>
  <section id="service" class="services-section">
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="section-title text-center">
            <h3>{{ $t("services.title") }}</h3>
            <p>{{ $t("services.description") }}</p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" sm="6" lg="4"  v-bind:key="index" v-for="(service, index) in services">
          <div class="feature-2">
            <div class="media">
              <div class="pull-left">
                <i>
                  <font-awesome-icon :icon="service.icon"/>
                </i>
                <div class="border"></div>
              </div>
              <div class="media-body">
                <h4 class="media-heading">{{service.name}}</h4>
                <p>{{service.description}}</p>
              </div>
            </div>
          </div>
        </b-col>
        <!-- /.col-lg-4 -->
      </b-row>
      <!-- /.row -->
    </b-container>
    <!-- /.container -->
  </section>
</template>
<script>
import Services from '../services/services.service.js';

export default {
  name: 'Services',
  data() {
    return {
      services: []
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.services = Services.getData(this.$i18n.locale);
    }
  }
};
</script>